export const CONTENT_TYPES_TAB_OPTIONS = [
  { value: 'ai-chat', label: 'AI chat', icon: 'icon-rocket-alt', hideOnPreview: true },
  { value: 'updates', label: 'Updates', icon: 'custom:newsletter', hideOnPreview: true },
  { value: 'comments', label: 'Comments', icon: 'icon-chat-alt' },
  { value: 'reviews', label: 'Reviews', icon: 'custom:doc-check' },
];

export const CONTENT_TYPES_EVENTS = {
  create: 'CreateContentType',
};

export const CONTENT_TYPES = {
  websitecontents: 'websitecontents',
  newsletters: 'newsletters',
  videoprojects: 'videoprojects',
  ebooks: 'ebooks',
  webinars: 'webinars',
  podcasts: 'podcasts',
};

export const CONTENT_MODEL_TYPES = {
  [CONTENT_TYPES.websitecontents]: 'WebsiteContent',
  [CONTENT_TYPES.newsletters]: 'Newsletter',
  [CONTENT_TYPES.videoprojects]: 'VideoProject',
  [CONTENT_TYPES.ebooks]: 'Ebook',
  [CONTENT_TYPES.webinars]: 'Webinar',
  [CONTENT_TYPES.podcasts]: 'Podcast',
};

export const CONTENT_TYPES_SLUGS = {
  [CONTENT_TYPES.websitecontents]: 'websitecontents',
  [CONTENT_TYPES.newsletters]: 'newsletters',
  [CONTENT_TYPES.videoprojects]: 'videoprojects',
  [CONTENT_TYPES.ebooks]: 'ebooks',
  [CONTENT_TYPES.webinars]: 'webinars',
  [CONTENT_TYPES.podcasts]: 'podcasts',
};

export const CONTENT_ITEM_DRAFT = 1;
export const CONTENT_ITEM_SCHEDULED = 3;
export const CONTENT_ITEM_PUBLISHED = 4;
export const CONTENT_ITEM_STATUS_NAMES = {
  [CONTENT_ITEM_DRAFT]: 'Draft',
  [CONTENT_ITEM_SCHEDULED]: 'Scheduled',
  [CONTENT_ITEM_PUBLISHED]: 'Published',
};
